import assistance from './assistance.json';
import benefit from './benefit.json';

export default {
	name: 'ForBuyer',
	i18n: require('./i18n').default,
	data() {
		return {
			assistance,
			benefit,
		};
	},
};
