export default {
    messages: {
        uk: {
            'Центр перевірок авто у Вінниці': 'Центр перевірок авто у Вінниці',
            'Перший центр перевірок авто від AUTO.RIA у Вінниці! Відвідайте наш офлайн центр перевірок і отримайте допомогу із продажем чи купівлею авто.': 'Перший центр перевірок авто від AUTO.RIA у Вінниці! Відвідайте наш офлайн центр перевірок і отримайте допомогу із продажем чи купівлею авто.',
            //
            'Центр перевірок AUTO.RIA': 'Центр перевірок AUTO.RIA',
            'Перший центр перевірок авто від AUTO.RIA у Вінниці!': 'Перший центр перевірок авто від AUTO.RIA у Вінниці!',
            'Ми виходимо за межі інтернету, щоб зустрічатись із вами особисто й допомагати': 'Ми виходимо за межі інтернету, щоб зустрічатись із вами особисто й допомагати',
            'продавати швидко, а купувати безпечно': 'продавати швидко, а купувати безпечно',
            'Чим ми можемо бути корисні саме для вас?': 'Чим ми можемо бути корисні саме для вас?',
            'Хочу купити авто': 'Хочу купити авто',
            'Хочу продати авто': 'Хочу продати авто',
            'Перший Центр відкрито у Вінниці': 'Перший Центр відкрито у Вінниці',
            'вул. Келецька, 121': 'вул. Келецька, 121',
            '1-ий поверх, вхід зі сторони парковки': '1-ий поверх, вхід зі сторони парковки',
            'Працюємо щодня': 'Працюємо щодня',
            'з': 'з',
            'Подзвонити': 'Подзвонити',
            'менеджер Центру перевірок AUTO.RIA': 'менеджер Центру перевірок AUTO.RIA',
            'Номер телефону скопійовано': 'Номер телефону скопійовано',
            '*Для покращення обслуговування, ваша розмова може бути записана': '*Для покращення обслуговування, ваша розмова може бути записана',
            'Корисна інформація для вас:': 'Корисна інформація для вас:',
        },
        ru: {
            'Центр перевірок авто у Вінниці': 'Центр проверок авто в Виннице',
            'Перший центр перевірок авто від AUTO.RIA у Вінниці! Відвідайте наш офлайн центр перевірок і отримайте допомогу із продажем чи купівлею авто.': 'Первый центр проверок авто от AUTO.RIA в Виннице! Посетите наш офлайн центр проверок и получите помощь с продажей или покупкой авто.',
            //
            'Центр перевірок AUTO.RIA': 'Центр проверок AUTO.RIA',
            'Перший центр перевірок авто від AUTO.RIA у Вінниці!': 'Первый центр проверок авто от AUTO.RIA в Виннице!',
            'Ми виходимо за межі інтернету, щоб зустрічатись із вами особисто й допомагати': 'Мы выходим за пределы Интернета, чтобы встретиться с вами лично, помочь',
            'продавати швидко, а купувати безпечно': 'продать авто быстро, а купить - безопасно',
            'Чим ми можемо бути корисні саме для вас?': 'Выберите чем мы можем быть полезны вам',
            'Хочу купити авто': 'Хочу купить авто',
            'Хочу продати авто': 'Хочу продать авто',
            'Перший Центр відкрито у Вінниці': 'Первый Центр открыт в Виннице',
            'вул. Келецька, 121': 'ул. Келецкая, 121',
            '1-ий поверх, вхід зі сторони парковки': '1-й этаж, вход со стороны парковки',
            'Працюємо щодня': 'Работаем каждый день',
            'з': 'c',
            'Подзвонити': 'Позвонить',
            'менеджер Центру перевірок AUTO.RIA': 'менеджер Центра проверок AUTO.RIA',
            'Номер телефону скопійовано': 'Номер телефона скопирован',
            '*Для покращення обслуговування, ваша розмова може бути записана': '*Для улучшения обслуживания, ваш разговор может быть записан',
            'Корисна інформація для вас:': 'Полезная информация для вас:',
        }
    }
}
