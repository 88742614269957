export default {
    messages: {
        uk: {
            'Перевірене авто продається швидше': 'Перевірене авто продається швидше',
            'Система перевірок на AUTO.RIA — інструмент, який дає покупцям важливу інформацію про ваш автомобіль. А заодно заощаджує ваш час на дзвінки, огляд та продаж у цілому': 'Система перевірок на AUTO.RIA — інструмент, який дає покупцям важливу інформацію про ваш автомобіль. А заодно заощаджує ваш час на дзвінки, огляд та продаж у цілому',
            'Більше про перевірки': 'Більше про перевірки',
            //
            "Спеціалісти Центру перевірок допоможуть <span class='bold'>легко додати і швидко продати авто:</span>": "Спеціалісти Центру перевірок допоможуть <span class='bold'>легко додати і швидко продати авто:</span>",
            'Спеціалісти Центру перевірок допоможуть': 'Спеціалісти Центру перевірок допоможуть',
            'легко додати і швидко продати:': 'легко додати і швидко продати:',
            'Розрахують середню ринкову ціну для вашої пропозиції': 'Розрахують середню ринкову ціну для вашої пропозиції',
            'Оглянуть ваше авто й заміряють лакофарбове покриття': 'Оглянуть ваше авто й заміряють лакофарбове покриття',
            'Допоможуть розмістити авто вище в пошуку й уже із перевіреною для покупців інформацією': 'Допоможуть розмістити авто вище в пошуку й уже із перевіреною для покупців інформацією',
            'Підкажуть, як правильно рекламувати свою пропозицію': 'Підкажуть, як правильно рекламувати свою пропозицію',
            'Відвідавши Центр, ви отримаєте:': 'Відвідавши Центр, ви отримаєте:',
            'Підтвердження того, що авто варте озвученої вами суми': 'Підтвердження того, що авто варте озвученої вами суми',
            'Спеціальні позначки на AUTO.RIA, з якими пропозиція буде вигідно виділятись у пошуку та отримає більше довіри покупців': 'Спеціальні позначки на AUTO.RIA, з якими пропозиція буде вигідно виділятись у пошуку та отримає більше довіри покупців',
        },
        ru: {
            'Перевірене авто продається швидше': 'Проверенное авто продается быстрее',
            'Система перевірок на AUTO.RIA — інструмент, який дає покупцям важливу інформацію про ваш автомобіль. А заодно заощаджує ваш час на дзвінки, огляд та продаж у цілому': 'Система проверок на AUTO.RIA — инструмент, дающий покупателям важную информацию о вашем автомобиле.',
            'Більше про перевірки': 'Больше о проверках',
            //
            "Спеціалісти Центру перевірок допоможуть <span class='bold'>легко додати і швидко продати авто:</span>": "Специалисты Центра проверок помогут <span class='bold'>легко добавить и быстро продать авто:</span>",
            'Спеціалісти Центру перевірок допоможуть': 'Специалисты Центра проверок помогут',
            'легко додати і швидко продати:': 'легко добавить и быстро продать:',
            'Розрахують середню ринкову ціну для вашої пропозиції': 'Рассчитают среднюю рыночную цену для вашего авто',
            'Оглянуть ваше авто й заміряють лакофарбове покриття': 'Осмотрят ваше авто и замеряют лакокрасочное покрытие.',
            'Допоможуть розмістити авто вище в пошуку й уже із перевіреною для покупців інформацією': 'Помогут разместить авто выше в поиске и уже с проверенной для покупателей информацией',
            'Підкажуть, як правильно рекламувати свою пропозицію': 'Подскажут, как правильно рекламировать ваше предложение',
            'Відвідавши Центр, ви отримаєте:': 'Посетив Центр, вы получите:',
            'Підтвердження того, що авто варте озвученої вами суми': 'Подтверждение того, что авто стоит озвученной вами суммы',
            'Спеціальні позначки на AUTO.RIA, з якими пропозиція буде вигідно виділятись у пошуку та отримає більше довіри покупців': 'Специальные отметки на AUTO.RIA с которыми ваше предложение будет выделиться в поиске и получит больше доверия покупателей',
        }
    }
}
