export default {
    messages: {
        uk: {
            'Як купити б/у авто і не помилитися?': 'Як купити вживане авто і не помилитися?',
            'Ви вирішили придбати автомобіль і вже розпочали пошуки? Отож ця інформація неодмінно буде корисною для вас, як вже стала в пригоді тисячам автомобілістів, котрі скористались цими порадами та не купили «кота в мішку». Отже, до діла!': 'Ви вирішили придбати автомобіль і вже розпочали пошуки? Отож ця інформація неодмінно буде корисною для вас, як вже стала в пригоді тисячам автомобілістів, котрі скористались цими порадами та не купили «кота в мішку». Отже, до діла!',
            'Як авто з Америки підготувати для експлуатації в Європі?': 'Як авто з Америки підготувати для експлуатації в Європі?',
            'Авто зі США здобувають все більшу популярність в Україні – зараз таких у пропозиціях про продаж легковиків на AUTO.RIA близько 14%, а серед «свіжопригнаних», тобто таких, які нещодавно прибули із-за кордону та були розмитнені, майже 40%. Проте є певні відмінності між машинами для північноамериканського та європейського ринку, про які слід знати тим, хто придбав або планує придбати такий автомобіль, та налаштований безпечно і не порушуючи місцевих правил експлуатувати його в Україні.': 'Авто зі США здобувають все більшу популярність в Україні – зараз таких у пропозиціях про продаж легковиків на AUTO.RIA близько 14%, а серед «свіжопригнаних», тобто таких, які нещодавно прибули із-за кордону та були розмитнені, майже 40%. Проте є певні відмінності між машинами для північноамериканського та європейського ринку, про які слід знати тим, хто придбав або планує придбати такий автомобіль, та налаштований безпечно і не порушуючи місцевих правил експлуатувати його в Україні.',
            'Все, що потрібно знати про перевірки авто на AUTO.RIA': 'Все, що потрібно знати про перевірки авто на AUTO.RIA',
            'Мабуть, кожен продавець та покупець авто на AUTO.RIA щось чули про перевірки авто. Але не всі можуть розуміти, що саме перевіряється, як і навіщо. Давайте розбиратись.': 'Мабуть, кожен продавець та покупець авто на AUTO.RIA щось чули про перевірки авто. Але не всі можуть розуміти, що саме перевіряється, як і навіщо. Давайте розбиратись.',
            'Як правильно підготувати автомобіль до продажу?': 'Як правильно підготувати автомобіль до продажу?',
            'Щодня в Україні продається (на основі даних 2021 року) 3 850 легкових авто з пробігом. Здається, що це чимало, однак охочих продати значно більше – близько 200 тис. Тому за таких умов варто знати та застосовувати певні рішення, які можуть допомогти виграти у цій конкурентній боротьбі з іншими продавцями.': 'Щодня в Україні продається (на основі даних 2021 року) 3 850 легкових авто з пробігом. Здається, що це чимало, однак охочих продати значно більше – близько 200 тис. Тому за таких умов варто знати та застосовувати певні рішення, які можуть допомогти виграти у цій конкурентній боротьбі з іншими продавцями.',
            'Хороший автомобіль довго продається? Чому так буває та як прискорити продаж': 'Хороший автомобіль довго продається? Чому так буває та як прискорити продаж',
            'Серед тих, хто займається продажем автомобілів побутує думка, що у майже у всіх випадках ключовим моментом є ціна. Простішими словами – якщо котрась машина «зависла» надовго, варто «скинути» кілька сотень доларів, як тут же знайдеться покупець. Звісно, ціна важлива, і якщо вона завищена, або ж стан автівки не відповідає сумі, яку за неї просять, тоді дійсно такий товар довгенько збиратиме пил на стоянці.': 'Серед тих, хто займається продажем автомобілів побутує думка, що у майже у всіх випадках ключовим моментом є ціна. Простішими словами – якщо котрась машина «зависла» надовго, варто «скинути» кілька сотень доларів, як тут же знайдеться покупець. Звісно, ціна важлива, і якщо вона завищена, або ж стан автівки не відповідає сумі, яку за неї просять, тоді дійсно такий товар довгенько збиратиме пил на стоянці.',
            'Читати далі': 'Читати далі',
        },
        ru: {
            'Як купити вживане авто і не помилитися?': 'Как купить подержанное авто и не ошибиться?',
            'Ви вирішили придбати автомобіль і вже розпочали пошуки? Отож ця інформація неодмінно буде корисною для вас, як вже стала в пригоді тисячам автомобілістів, котрі скористались цими порадами та не купили «кота в мішку». Отже, до діла!': 'Вы решили приобрести автомобиль и уже начали поиски? Итак, эта информация непременно будет полезна для вас, как уже пригодилась тысячам автомобилистов, которые воспользовались этими советами и не купили «кота в мешке».',
            'Як авто з Америки підготувати для експлуатації в Європі?': 'Как авто из Америки подготовить для эксплуатации в Европе?',
            'Авто зі США здобувають все більшу популярність в Україні – зараз таких у пропозиціях про продаж легковиків на AUTO.RIA близько 14%, а серед «свіжопригнаних», тобто таких, які нещодавно прибули із-за кордону та були розмитнені, майже 40%. Проте є певні відмінності між машинами для північноамериканського та європейського ринку, про які слід знати тим, хто придбав або планує придбати такий автомобіль, та налаштований безпечно і не порушуючи місцевих правил експлуатувати його в Україні.': 'Авто из США приобретают все большую популярность в Украине – сейчас таких в предложениях о продаже легковых автомобилей на AUTO.RIA около 14%, а среди «свежепригнанных», то есть недавно прибывших из-за рубежа и растаможенных, почти 40%. Однако есть определенные отличия между машинами для североамериканского и европейского рынка, о которых следует знать тем, кто приобрел или планирует приобрести такой автомобиль, и настроен безопасно и не нарушая местные правила эксплуатировать его в Украине.',
            'Все, що потрібно знати про перевірки авто на AUTO.RIA': 'Все, что нужно знать о проверках авто на AUTO.RIA',
            'Мабуть, кожен продавець та покупець авто на AUTO.RIA щось чули про перевірки авто. Але не всі можуть розуміти, що саме перевіряється, як і навіщо. Давайте розбиратись.': 'Пожалуй, каждый продавец и покупатель авто на AUTO.RIA слышали о проверках авто. Но не все могут понимать, что именно проверяется, как и зачем. Давайте разбираться.',
            'Як правильно підготувати автомобіль до продажу?': 'Как правильно подготовить автомобиль к продаже?',
            'Щодня в Україні продається (на основі даних 2021 року) 3 850 легкових авто з пробігом. Здається, що це чимало, однак охочих продати значно більше – близько 200 тис. Тому за таких умов варто знати та застосовувати певні рішення, які можуть допомогти виграти у цій конкурентній боротьбі з іншими продавцями.': 'Ежедневно в Украине продается (на основе данных 2021) 3 850 легковых авто с пробегом. Кажется, что это немало, однако желающих продать гораздо больше – около 200 тыс. Поэтому при таких условиях следует знать и применять определенные решения, которые могут помочь выиграть конкурентную борьбу с другими продавцами.',
            'Хороший автомобіль довго продається? Чому так буває та як прискорити продаж': 'Хороший автомобиль долго продается? Почему так бывает и как ускорить продажу',
            'Серед тих, хто займається продажем автомобілів побутує думка, що у майже у всіх випадках ключовим моментом є ціна. Простішими словами – якщо котрась машина «зависла» надовго, варто «скинути» кілька сотень доларів, як тут же знайдеться покупець. Звісно, ціна важлива, і якщо вона завищена, або ж стан автівки не відповідає сумі, яку за неї просять, тоді дійсно такий товар довгенько збиратиме пил на стоянці.': 'Какие проблемы часто возникают при продаже авто и как их избежать - разбираемся вместе.',
            'Читати далі': 'Читать дальше',
        }
    }
}
