import information from './information.json';

export default {
	name: 'Information',
	i18n: require('./i18n').default,
	data() {
		return {
			information,
		};
	},
};
