import i18n from './i18n';
import { mapGetters } from "vuex";

export default {
  name: 'Car',
  i18n,
  props: {
    car: {
      type: Object,
      default: () => ({
        id: '',
        uri: '',
        title: '',
      }),
    },
  },
  data() {
    return {
      isWebp: false,
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
    }),
    photoSRC() {
      const { brand = {}, model = {}, photos = {} } = this.car;

      if (brand?.eng && model?.eng && photos?.main?.id) {
        this.isWebp = true;
        return `https://cdn.riastatic.com/photosnew/auto/photo/${brand.eng}_${model.eng}__${photos.main.id}${this.isMobile ? 'f' : 'bx'}`;
      }

      this.isWebp = false;
      return '';
    },
    reportId() {
      return this.car?.autotest?.technicalPackage?.orderId;
    },
    reportLink() {
      return `${this.langPrefix}/vue/technicalcheck/${this.reportId}`;
    },
    reportCreatedAt() {
      return new Date(this.car?.autotest?.technicalPackage?.addDate).toLocaleDateString('ro-RO');
    },
    priceValue() {
      return this.car?.price?.main?.value;
    },
    priceSign() {
      return this.car?.price?.main?.currency?.sign;
    },
  },
};
