import { mapActions, mapGetters } from 'vuex';
import Car from './Car/index.vue';
import i18n from './i18n';

export default {
  name: 'CarsChecked',
  i18n,
  data() {
    return {
      maxPage: 0,
      pageNum: 0,
      idsAll: [],
      idsCurrent: [],
      countItemsOnList: 3,
      itemsCurrent: undefined,
      pagesItems: {},
      searchParams: {
        indexName: 'auto,order_auto,newauto_search',
        'categories.main.id': 1,
        'region.id[0]': 1,
        'price.currency': 1,
        'abroad.not': 0,
        'custom.not': 1,
        with_autotest_corporate_check: 1,
        size: 10,
      },
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
    }),
    page: {
      get() {
        return this.pageNum;
      },
      set(value) {
        if (value >= 0 && value <= this.maxPage) {
          this.itemsCurrent = undefined;
          this.pageNum = value;
          this.setCurrentIds();
        }
      },
    },
    isButtonPrevEnabled() {
      return this.page > 0;
    },
    isButtonNextEnabled() {
      return this.page < this.maxPage;
    },
    urlQuery() {
      return Object.entries(this.searchParams).reduce((res, item, key, arr) => {
        res += item.join('=');

        if (key < arr.length - 1) res += '&';

        return res;
      }, '');
    },
    allCheckedCarsUrl() {
      return `${this.langPrefix}/search/?${this.urlQuery}`;
    },
  },
  components: {
    Car,
  },
  methods: {
    ...mapActions({
      checkedCarIds: 'search/advertisement/searchAPI',
      rotatorChecked: 'rotator/rotatorVideo',
    }),
    setCurrentIds() {
      this.idsCurrent = this.idsAll.slice(
        this.countItemsOnList * this.pageNum,
        this.countItemsOnList * (this.pageNum + 1),
      );

      if (this.pagesItems[this.idsCurrent.join('-')]) {
        this.itemsCurrent = this.pagesItems[this.idsCurrent.join('-')];
      } else {
        this.rotatorChecked({
          ids: this.idsCurrent,
        }).then((result) => {
          this.pagesItems[this.idsCurrent.join('-')] = result;
          this.itemsCurrent = result;
        });
      }
    },
  },
  mounted() {
    this.checkedCarIds(this.searchParams).then((response) => {
      this.idsAll = response?.result?.search_result?.ids || [];
      this.maxPage = Math.floor(this.idsAll.length / this.countItemsOnList) - 1;
      this.setCurrentIds();
    });
  },
};
