export default {
    messages: {
        uk: {
            'Обирайте надійне та перевірене авто': 'Обирайте надійне та перевірене авто',
            'AUTO.RIA пропонує систему перевірок, з якою ви можете дізнатись усе як про минуле автомобіля, так і про його актуальний стан': 'AUTO.RIA пропонує систему перевірок, з якою ви можете дізнатись усе як про минуле автомобіля, так і про його актуальний стан',
            'Більше про перевірки': 'Більше про перевірки',
            //
            "Спеціалісти Центру перевірок допоможуть <span class='bold'>безпечно купити вживане авто:</span>": "Спеціалісти Центру перевірок допоможуть <span class='bold'>безпечно купити вживане авто:</span>",
            'Спеціалісти Центру перевірок допоможуть': 'Спеціалісти Центру перевірок допоможуть',
            'безпечно купити вживане авто:': 'безпечно купити вживане авто:',
            'Підберуть надійне авто для ваших потреб': 'Підберуть надійне авто для ваших потреб',
            'Оцінять поверхневий стан авто і зафіксують на відео лакофарбове покриття': 'Оцінять поверхневий стан авто і зафіксують на відео лакофарбове покриття',
            'Підберуть необхідні перевірки для вибраного авто — за VIN-кодом чи на СТО': 'Підберуть необхідні перевірки для вибраного авто — за VIN-кодом чи на СТО',
            'Організують перевірку на кращих СТО країни': 'Організують перевірку на кращих СТО країни',
            'Відвідавши Центр, ви отримаєте:': 'Відвідавши Центр, ви отримаєте:',
            'Історію авто зі справжнім пробігом, к-стю власників, ДТП, страховими випадками, тощо': 'Історію авто зі справжнім пробігом, к-стю власників, ДТП, страховими випадками, тощо',
            'Детальний звіт про технічний стан авто': 'Детальний звіт про технічний стан авто',
            'Впевненість, що ваша угода буде безпечною й без небажаних сюрпризів після покупки': 'Впевненість, що ваша угода буде безпечною й без небажаних сюрпризів після покупки',
            'Приклад звіту': 'Приклад звіту',
        },
        ru: {
            'Обирайте надійне та перевірене авто': 'Выбирайте надежное и проверенное авто',
            'AUTO.RIA пропонує систему перевірок, з якою ви можете дізнатись усе як про минуле автомобіля, так і про його актуальний стан': 'AUTO.RIA предлагает систему проверок, с которой вы можете узнать все как о прошлом автомобиля, так и о его актуальном состоянии',
            'Більше про перевірки': 'Больше о проверках',
            //
            "Спеціалісти Центру перевірок допоможуть <span class='bold'>безпечно купити вживане авто:</span>": "Специалисты Центра проверок помогут <span class='bold'>безопасно купить подержанное авто:</span>",
            'Спеціалісти Центру перевірок допоможуть': 'Специалисты Центра проверок помогут',
            'безпечно купити вживане авто:': 'безопасно купить подержанное авто:',
            'Підберуть надійне авто для ваших потреб': 'Подберут надежный автомобиль для ваших нужд',
            'Оцінять поверхневий стан авто і зафіксують на відео лакофарбове покриття': 'Оценят поверхностное состояние авто и зафиксируют на видео лакокрасочное покрытие',
            'Підберуть необхідні перевірки для вибраного авто — за VIN-кодом чи на СТО': 'Подберут необходимые проверки для выбранного авто — по VIN-коду или на СТО',
            'Організують перевірку на кращих СТО країни': 'Организуют проверку на лучших СТО страны',
            'Відвідавши Центр, ви отримаєте:': 'Посетив Центр, вы получите:',
            'Історію авто зі справжнім пробігом, к-стю власників, ДТП, страховими випадками, тощо': 'Историю авто с настоящим пробегом, кол-во владельцев, ДТП, страховыми случаями и т.п.',
            'Детальний звіт про технічний стан авто': 'Подробный отчет о техническом состоянии авто',
            'Впевненість, що ваша угода буде безпечною й без небажаних сюрпризів після покупки': 'Уверенность, что ваша покупка будет безопасной и без нежелательных сюрпризов',
            'Приклад звіту': 'Пример отчета',
        }
    }
}
