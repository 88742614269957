import ForBuyer from './ForBuyer/index.vue';
import ForSeller from './ForSeller/index.vue';
import CarsChecked from './CarsChecked/index.vue';
import Information from './Information/index.vue';
import GoogleMap from '../Common/GoogleMap/index.vue';
import ForCommunications from './ForCommunications/index.vue';
import VerifiedByPeople from '../Common/VerifiedByPeople/index.vue';

import i18n from './i18n';

export default {
  name: 'LandingVerificationCenter',
  i18n,
  props: {
    geolocation: {
      type: Object,
      default() {
        return {
          lat: 49.22747961594334,
          lng: 28.396133802803437,
        };
      },
    },
  },
  metaInfo() {
    return {
      title: `${this.$t('Центр перевірок авто у Вінниці')}`,
      meta: [
        {
          name: 'description',
          content: this.$t('Перший центр перевірок авто від AUTO.RIA у Вінниці! Відвідайте наш офлайн центр перевірок і отримайте допомогу із продажем чи купівлею авто.'),
        },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        {
          property: 'og:title',
          content: `${this.$t('Центр перевірок авто у Вінниці')}`,
        },
        {
          property: 'og:description',
          content: this.$t('Перший центр перевірок авто від AUTO.RIA у Вінниці! Відвідайте наш офлайн центр перевірок і отримайте допомогу із продажем чи купівлею авто.'),
        },
        { property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png' },
        { name: 'ROBOTS', content: 'INDEX, FOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/verification-center/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/verification-center/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/verification-center/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/verification-center/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/verification-center/' },
        { rel: 'canonical', href: `https://auto.ria.com${this.langPrefix}/verification-center/` },
      ],
    };
  },
  data() {
    return {
      forBuyer: true,
      showCopyText: false,
      mapStatus: false,
      mapConfig: {
        zoom: 16,
        disableDefaultUI: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      },
    };
  },
  components: {
    ForBuyer,
    ForSeller,
    CarsChecked,
    Information,
    GoogleMap,
    ForCommunications,
    VerifiedByPeople,
  },
  methods: {
    sendVFCLogs(block, action) {
      return this._slonik({
        previous_url: document.referrer,
        event_id: 1067,
        action_block: block,
        click_action: action,
      });
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.showCopyText = true;
      setTimeout(() => {
        this.showCopyText = false;
      }, 3000);
    },
  },
  mounted() {
    this.sendVFCLogs(0, 0);
  },
};
