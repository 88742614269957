import { mapActions, mapGetters } from 'vuex';
import i18n from './i18n';

export default {
  name: 'ForCommunications',
  i18n,
  data() {
    return {
      from: '09:00',
      to: '18:00',
      contactPhone: '+380930429264',
      viberLink: 'viber://chat?number=%2B380674313453',
      telegramLink: 'tg://resolve?domain=RiaCentre',
      phone: '',
      convenientTime: undefined,
      isSubmit: false,
      showError: false,
      triggerId: 3788,
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
    }),
  },
  methods: {
    ...mapActions({
      sendCallBackRequest_: 'others/sendCallBackRequest',
    }),
    async sendCallBackRequest() {
      const { phone, convenientTime: callTime = '', triggerId } = this;

      if (phone) {
        const dataParams = {
          phone,
          ...callTime && callTime,
        };

        try {
          const { data } = await this.sendCallBackRequest_({ triggerId, data: dataParams });

          if (data.notification_id) {
            this.showError = false;
            this.isSubmit = true;
          }
        } catch (err) {
          this.showError = true;
        }
      }
    },
    onInput(event) {
      const value = event.target.value.replace(/\D+/g, '');
      const numberLength = 12;
      let result = '';

      for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
          case 0:
            result += '+38 (0';

            if (value.length === 1) result += value[i];
            continue;
          case 1:
          case 2:
            continue;
          case 5:
            result += ') ';
            break;
          case 8:
          case 10:
            result += ' ';
            break;
          default:
            break;
        }

        result += value[i];
      }

      this.phone = result;
    },
  },
};
