export default {
    messages: {
        uk: {
            'Авто, перевірені в Центрі перевірок AUTO.RIA': 'Авто, перевірені в Центрі перевірок AUTO.RIA',
            'Перевірений VIN-код': 'Перевірений VIN-код',
            'Перевірений технічно': 'Перевірений технічно',
            'Перевірено': 'Перевірено',
            'Переглянути звіт': 'Переглянути звіт',
            'Всі перевірені авто': 'Всі перевірені авто',
        },
        ru: {
            'Авто, перевірені в Центрі перевірок AUTO.RIA': 'Авто, проверенные в Центре проверок AUTO.RIA',
            'Перевірений VIN-код': 'Проверен VIN-код',
            'Перевірений технічно': 'Проверен технически',
            'Перевірено': 'Проверено',
            'Переглянути звіт': 'Просмотреть отчет',
            'Всі перевірені авто': 'Все проверенные авто',
        }
    }
}
